.background-image-container {
  background: url('../../public/assets/background.png') center center no-repeat;
  padding: 80px 0;
  height: 300px;
  height: auto;
  color: #6a6a6a;
  display: inline-block;
  text-align: center;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-title {
  font-size: 32px;
  color: white;
  font-family: Montserrat;
  text-transform: uppercase;
  font-weight: 600;
}
.login-subtitle {
  font-size: 15px;
  color: white;
  font-family: Montserrat;
}
.login-input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #673995;
}
.login-link {
  color: #333;
}
.login-link:hover {
  color: #673995;
}
.login-button {
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #673995;
  border-radius: 5px;
  color: #333;
  display: inline-block;
  line-height: normal;
  z-index: 1;
  overflow: hidden;
  position: relative;
  padding: 15px 20px;

  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  font-family: Montserrat;
  font-weight: 600;
  text-transform: uppercase;
}
.login-button:hover {
  background-color: #673995;
  color: white;
}
