.footer-contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer-map-info {
  width: 400px;
}
.newsletter-email {
  background-color: unset;
  border: 0px;
  border-bottom: 1px solid #5a3584;
  width: 280px;
  color: black;
  letter-spacing: 0em;
  font-family: Cardo;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}
.newsletter-email::placeholder {
  color: black;
  font-family: Cardo;
}
.newsletter-button {
  background-color: #5a3584;
  color: white;
  border-radius: 50px;
  margin-top: 6px;
  padding: 10px 50px;
}
.newsletter-subscription-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}
