* {
	box-sizing: border-box;
}

main {
	min-height: 80vh;
}

::-webkit-scrollbar-track {
	background-color: #f5f5f5;
}

::-webkit-scrollbar {
	width: 8px;
	background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(66, 66, 66, 0.2);
	border: 0px;
	background-clip: padding-box;
	border-radius: 5px;
}

.custom-page-link-color {
	border-color: #a8dadc;
}

/* Loader */
.loader {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30%;
	width: 80px;
	height: 80px;
	padding-left: 0;
}

.loader:after {
	content: ' ';
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid #a8dadc;
	border-color: #a8dadc transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* Ratings */

.rating-outer {
	display: inline-block;
	position: relative;
	font-family: FontAwesome;
	color: #fdcc0d;
}

.rating-outer::before {
	content: '\f006 \f006 \f006 \f006 \f006';
}

.rating-inner {
	position: absolute;
	top: 0;
	left: 0;
	white-space: nowrap;
	overflow: hidden;
	width: 0;
}

.rating-inner::before {
	content: '\f005 \f005 \f005 \f005 \f005';
	color: #f8ce0b;
}

.carousel .thumbs {
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 10px;
}

.carousel .thumb {
	margin: 0 5px;
	cursor: pointer;
}

@media (min-width: 1025px) {
	.h-custom {
		height: 100vh !important;
	}
}

@media only screen and (max-width: 990px) {
	.summary {
		margin-top: -100px;
	}
}

/* Checkout Steps */

.checkout-progress div {
	box-sizing: border-box;
}

.checkout-progress {
	display: block;
	clear: both;
	margin: 20px auto;
	width: auto;
	font-family: sans-serif;
	overflow: auto;
}

.step {
	margin: 0;
	border: 0;
	letter-spacing: 1px;
	line-height: 30px;
	padding: 5px 10px 5px 15px;
	color: grey;
	text-decoration: none;
	cursor: default;
	font-weight: bold;
	float: left;
	height: auto;
}

.incomplete {
	background: #eeeeee;
}

.active-step {
	background: #fa9c23;
	color: #fff;
}

.triangle-active {
	float: left;
	width: 0;
	border-top: 20px solid transparent;
	border-left: 15px solid #fa9c23;
	border-bottom: 20px solid transparent;
	margin-left: -1px;
}

.triangle2-active {
	width: 0;
	float: left;
	border-top: 20px solid #fa9c23;
	border-left: 15px solid #fff;
	border-bottom: 20px solid #fa9c23;
	margin-right: -1px;
}

.triangle-incomplete {
	float: left;
	width: 0;
	border-top: 20px solid transparent;
	border-left: 15px solid #eeeeee;
	border-bottom: 20px solid transparent;
	margin-left: -1px;
}

.triangle2-incomplete {
	width: 0;
	float: left;
	border-top: 20px solid #eeeeee;
	border-left: 15px solid #fff;
	border-bottom: 20px solid #eeeeee;
	margin-right: -1px;
}

.dataTables_paginate {
	margin-top: 25px;
}

@media only screen and (max-width: 768px) {
	.dataTables_paginate {
		display: flex;
		justify-content: center;
		margin-top: 25px;
	}
}

.dataTables_length {
	display: none;
}

.mdb-datatable-filter {
	margin-bottom: 15px;
	width: 100%;
}
