.product-card-container {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  position: relative;
}
.product-card-container:hover {
  border: 1px solid #673995;
}
.promo-percentage {
  background-color: #f3c69b;
  padding: 15px;
  display: flex;
  position: absolute;
  right: 20px;
  justify-content: center;
  border-radius: 50%;
  color: white;
  width: 53px;
  height: 53px;
}
.product-image-container {
  width: 220px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-image {
  width: 220px;
  height: 220px;
  padding-bottom: 10px;
  object-fit: cover;
}
.product-card-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.product-title {
  color: #333;
  display: inline-block;
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 700;
  width: 100%;
  overflow: hidden;
  width: 300px;
  text-align: center;
  margin: 0 0 5px;
  line-height: normal;
}
.product-name {
  color: #333;
  display: inline-block;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 700;
  width: 100%;
  overflow: hidden;
  text-align: center;
  margin: 0 0 5px;
  line-height: normal;
}
.view-details-button {
  /* margin-top: 15px;
  border: 1px solid #f9c3bb;
  border-radius: 5px;
  color: #333;
  display: inline-block;
  line-height: normal;
  z-index: 1;
  overflow: hidden;
  position: relative;
  padding: 15px 20px;

  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  font-family: Montserrat;
  font-weight: 600;
  text-transform: uppercase; */
  color: white;
  background-color: #673995;
  padding: 5px 15px;
}
.view-details-button:hover {
  background-color: #f9c3bb;
  color: white;
}
