.reset-title {
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0em;
    margin: 0 0 15px;
    line-height: 1.4;
    font-size: 36px;
    color: #1a1a1a;

}.reset-description {
    font-family: Montserrat;
    font-weight: 500;
    letter-spacing: 0em;
    margin: 0 0 15px;
    line-height: 1.4;
    font-size: 16px;
    color: #1a1a1a;
}
.reset-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;

}