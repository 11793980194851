:where(.css-dev-only-do-not-override-djtmh8).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #673995;
  background-color: #673995;
}
/* ProductTeintes.css */

.teintes-container {
  width: 100%;
  padding: 20px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.teintes-scroll-list {
  display: flex;
  -webkit-mask: linear-gradient(90deg, #000 0%, #000 90%, #0000 100%);

  gap: 11px;
  padding: 10px;
  background: unset;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background-color: unset;
}

::-webkit-scrollbar-thumb {
  background-color: unset;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: unset;
}

.teintes-scroll-list.dragging {
  cursor: grabbing;
}

.teinte-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 5px;
}

.see-all-btn {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.see-all-btn:hover {
  background-color: #0056b3;
}

.teintes-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  width: 80%;
  max-height: 90%;
  overflow-y: scroll;
  position: relative;
  border-radius: 10px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}

.teintes-modal-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.teinte-image-modal {
  width: 100px;
  height: 100px;
  object-fit: cover;
  z-index: 999;
  margin: 5px;
  border-radius: 5px;
}
.teintes-modal-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  padding-bottom: 15px;
}
.ant-modal-content {
  width: 90%;
}
.add-cart-btn {
  color: white;
  background-color: #5a3584;
  color: white;
  border-radius: 7px;
  padding: 8px 30px;
}
.products-colors-container {
  display: flex;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
