.navigation-item {
  color: white;
  font-size: 1.2em;
  font-weight: 600;
  text-decoration: none;
  line-height: 6.6666666667em;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  display: block;
  position: relative;
  overflow: hidden;
  font-family: Montserrat;

  cursor: pointer;
}
.categories-list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
.gategory-title {
  color: #333;
  display: inline-block;
  font-size: 19px;
  font-family: Montserrat;
  font-weight: 700;
  text-align: center;
  margin: 0 0 5px;
  min-width: 140px;
  text-align: left;
  line-height: normal;
}
.ant-divider-horizontal {
  margin: 4px;
  width: 100%;
}
.gategory-title:hover {
  color: #f9c3bb;
  cursor: pointer;
}
.categories-items-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
}
.navigation-item:hover {
  color: #f9c3bb;
}
@media (max-width: 992px) {
  .navigation-item {
    color: #111;
    font-size: 1em;
    font-weight: 600;
    text-decoration: none;
    line-height: 2.6666666667em;
  }

  .navbar-nav-items {
    display: flex;
    gap: 10px !important;
    flex-direction: column;
  }
}
.navbar-flag-container {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-bottom: 0px;
}
p {
  margin-bottom: 0px;
}
.search-header-input {
  display: flex;
  border: 0px;
  border-radius: 15px;
  outline: none;
  box-shadow: none;
  width: 300px;
}
@media (max-width: 500px) {
  .search-header-input {
    width: 100%;
  }
}
.search-header-input-container {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 15px;
  border: 1px solid #5a3584;
  margin-right: 140px;
}
@media (max-width: 500px) {
  .search-header-input-container {
    margin-right: 0px;
  }
}
