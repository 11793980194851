.carousel-item {
  position: relative;
  display: flex;
  align-items: center;
}

.carousel-item img {
  width: 100%;
}

.carousel-caption {
  position: absolute;
  left: 10%;
  top: 25%;
  bottom: 0;
  font-size: 25px;
  letter-spacing: 0px;
  text-align: left;
  width: 100%;
  max-width: 500px;
  text-transform: uppercase;
  color: rgb(62, 55, 55);
  font-weight: 600;
  font-family: Montserrat;
  padding: 10px;
}
